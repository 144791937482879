import './stage-home.scss';

class StartStage {
    constructor ($element, options) {
        this.$stage = $element;
        this.$video = this.$stage.querySelector('[data-start-stage="video"]');
        this.$controls = null;
    }

    initialize () {
        if (this.$video) {
            this.handleVideo();
            this.checkSize();
            [768, 320].forEach((bp) => {
                window.mediaMatchHandler[bp].addEventListener('change', () => {
                    this.checkSize();
                });
            });
        }
    }

    handleVideo () {
        this.$controls = this.$stage.querySelector('[data-start-stage="video-controls"]');
        this.$controls.addEventListener('click', (e) => {
            e.target.classList.toggle('is--play');
            if (this.$video.paused === true) {
                this.$video.play();
            } else {
                this.$video.pause();
            }
        });
    }

    checkSize () {
        if (window.mediaMatchHandler[768].matches) {
            this.$video.play();
            this.$controls.classList.add('is--play');
        } else {
            this.$video.pause();
        }
    }
}

export { StartStage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$stage = $context.querySelectorAll('[data-start-stage="root"]');

        for (let i = 0; i < $$stage.length; i++) {
            const $stage = new StartStage($$stage[i]);
            $stage.initialize();
        }
    }
});
